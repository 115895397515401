import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Agenda from '@images/learn-and-earn-agenda.svg';
import './styles.scss';

const LearnAndEarn = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "learn-and-earn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="LearnAndEarn">
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        alt="Learn and earn at Dream Factory"
      />
      <h1>
        Learn and earn
      </h1>
      <h2>
        A backend hands-on course
      </h2>
      <h3>
        Details
      </h3>
      <ul>
        <li>
          5 students
        </li>
        <li>
          12 weeks
        </li>
        <li>
          8 hours of lecture per week
        </li>
        <li>
          4 hours of homework per week
        </li>
        <li>
          33 kn/h
        </li>
      </ul>
      <h3>
        Agenda
      </h3>
      <img
        alt="Learn and Earn agenda"
        src={Agenda}
        width="100%"
      />
      <a href="mailto:learn@dream-factory.hr">
        Apply now
      </a>
    </div>
  );
}

export default LearnAndEarn;