import React from 'react';
import { Link } from 'gatsby';
import { Container } from '@components';
import './styles.scss';

const Careers = () => (
  <Container>
    <section className="Careers" id="careers">
      <div className="Careers-text">
        <div className="title">
          Careers
        </div>
        <p>
          We value human resources above all else. We believe investing in human potential is the only way to deliver outstanding results. Essential to this process is building an amazing place to work & grow.
        </p>
        <div className="notice">
          We are not currently hiring, however we are having an amazing experimental course.
        </div>
        <Link className="badge" to="/learn-and-earn">
          <span>Learn & Earn</span>
          #nostringsattached
        </Link>
        <Link className="badge-notification" to="/learn-and-earn">
          Apply now
        </Link>
      </div>
    </section>
  </Container>
);

export default Careers;