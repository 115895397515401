import React from 'react';
import './styles.scss';

const Hamburger = ({
  isOpen,
  toggleOpen,
}) => {
  return (
    <button
      aria-label="Mobile navigation menu"
      aria-pressed={isOpen}
      className={`Hamburger ${isOpen ? 'Hamburger-open' : ''}`}
      onClick={() => toggleOpen(!isOpen)}
      role="button"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

export default Hamburger;
